import { DoorWindow, DoorColor, DoorInsulationOption, DoorSpecial } from './index';
import { DoorHardware } from './door-hardware';
import { DoorLock } from './door-lock';
import { GlassType } from './glass-type';

export class DoorDesign {
    _id: string;
    name: string;
    code: string;
    imageURL: string;
    galleryImageURL: string;
    prepopulatedImageUrl: string;
    displayOrder: number;
    productGroupId: string;
    isLongPanel: boolean;
    windows: DoorWindow[];
    hardwareOptions: DoorHardware[];
    lockOptions: DoorLock[];
    insulationOptionsAndColors: [{
        insulationOption: DoorInsulationOption,
        colors: DoorColor[],
        glassTypes: GlassType[],
        disallowInches: boolean,
        windows: DoorWindow[]
    }];
    special: DoorSpecial;

    constructor(d?) {
        if (d) {
            this._id = d._id;
            this.name = d.name;
            this.imageURL = d.imageURL;
            this.galleryImageURL = d.galleryImageURL;
            this.productGroupId = d.productGroupId;
            this.isLongPanel = d.isLongPanel;
            this.windows = d.windows;
            this.hardwareOptions = d.hardwareOptions;
            this.lockOptions = [];
            this.insulationOptionsAndColors = d.insulationOptionsAndColors;
            this.special = d.special;
            this.code = d.code;
            this.prepopulatedImageUrl = d.prepopulatedImageUrl;
            this.displayOrder = d.displayOrder;

            if (d.lockOptions) {
                for (var i = 0; i < d.lockOptions.length; i++) {
                    let l = d.lockOptions[i];
                    if (l.canChangeQuantity) {
                        l.quantity = 0;
                    }
                    this.lockOptions.push(l);
                }
            }
        }
    }

    getInsulationOptions(width, inches): DoorInsulationOption[] {
        if (!this.insulationOptionsAndColors) return [];
        let insulationOptions: DoorInsulationOption[] = [];
        for (var i = 0; i < this.insulationOptionsAndColors.length; i++) {
            if (width && width.inches > 0) {
                if (this.insulationOptionsAndColors[i].disallowInches) continue;
            }
            insulationOptions.push(this.insulationOptionsAndColors[i].insulationOption);
        }
        insulationOptions = insulationOptions.sort((a, b) => (a.rValue < b.rValue) ? 1 : (a.rValue > b.rValue) ? -1 : 0);
        return insulationOptions;
    }

    getColors(insulationOption?: DoorInsulationOption): DoorColor[] {
        let colors: DoorColor[] = [];
        if (insulationOption) {
            let optionAndColor = this.insulationOptionsAndColors.find(ioc => ioc.insulationOption._id == insulationOption._id);
            if (optionAndColor == null) return colors;
            colors = optionAndColor.colors;
        } else {
            for (var i = 0; i < this.insulationOptionsAndColors.length; i++) {
                let optionColors = this.insulationOptionsAndColors[i].colors;
                colors = colors.concat(optionColors);
            }
        }
        let unique = {};
        for (var j = 0; j < colors.length; j++) {
            unique[colors[j]._id] = colors[j];
        }
        let uniqueColors = [];
        for (var key in unique) {
            uniqueColors.push(unique[key]);
        }
        return uniqueColors;
    }

    getGlassTypes(insulationOption?: DoorInsulationOption, window?: DoorWindow): GlassType[] {
        if (!insulationOption) return [];
        if (window && window.glassTypes && window.glassTypes.length > 0) return window.glassTypes;
        let optionsObject = this.insulationOptionsAndColors.find(ioc => ioc.insulationOption._id == insulationOption._id);
        if (optionsObject == null) return [];
        return optionsObject.glassTypes;
    }

    getGlassTypesObject(insulationOption?: DoorInsulationOption, window?: DoorWindow): Object {
        let gtObj = {};
        let glassTypes = this.getGlassTypes(insulationOption, window);
        if (glassTypes) {
            for (var i = 0; i < glassTypes.length; i++) {
                let gt = glassTypes[i];
                if (!gt.isInsulated) gtObj[gt.code] = gt;
            }
        }
        return gtObj;
    }

    updateInsulationOption(option: DoorInsulationOption) {
        let isFound = false;
        for (var i = 0; i < this.insulationOptionsAndColors.length; i++) {
            let optionAndColor = this.insulationOptionsAndColors[i];
            if (optionAndColor.insulationOption._id === option._id) {
                this.insulationOptionsAndColors[i].insulationOption = option;
                isFound = true;
                break;
            }
        }
        if (!isFound) {
            this.insulationOptionsAndColors.push({
                insulationOption: option,
                colors: [],
                glassTypes: [],
                disallowInches: false,
                windows: []
            });
        }
    }

    updateInsulationOptions(options: DoorInsulationOption[]) {
        if (!options) return;
        for (var j = 0; j < options.length; j++) {
            this.updateInsulationOption(options[j]);
        }
    }
}
